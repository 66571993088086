import axios from 'axios';
import {
  changeGlobalError
} from './globalError';
import getDefaultObject from '../../heplers/getDefaultObject';
import getDefaultHeader from '../../heplers/getDefaultHeader';
import getFileHeader from '../../heplers/getFileHeader';

import downloadFileFromUrl from '../../heplers/downloadFileFromUrl';

const BASE_API_PATH = process.env.REACT_APP_API_URL;

// Action types
const CHANGE_IS_LOADING = 'MESSAGES/ Change loading';
const ADD_MESSAGES = 'MESSAGES/ Add messages';
const CLEAR_MESSAGES = 'MESSAGES/ Clear messages';
const ADD_MESSAGES_SERVER_RESPONSE = 'MESSAGES/ Add server response';
const CLEAR_MESSAGES_SERVER_RESPONSE = 'MESSAGES/ Clear server response';

const ACCEPT_MESSAGE = 'MESSAGES/ Accept message';
const MSG_WORK = 'MESSAGES/ Local msg accept or smth work';

const SET_FILE_IS_LOADING = 'FILE/ Set is loading file flag';

// Reducer
const inititalState = {
  isLoading: false,
  data: {},
  serverResponse: {},
  msgWork: false,
  fileIsLoading: false,
};

const messages = (state = inititalState, action) => {
  switch (action.type) {
    case CHANGE_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case ADD_MESSAGES:
      return {
        ...state,
        isLoading: false,
          data: action.payload,
      };
    case CLEAR_MESSAGES:
      return {
        ...inititalState,
      };
    case ADD_MESSAGES_SERVER_RESPONSE:
      return {
        ...state,
        serverResponse: action.payload,
          isLoading: false,
      };
    case CLEAR_MESSAGES_SERVER_RESPONSE:
      return {
        ...state,
        serverResponse: {},
      };

    case ACCEPT_MESSAGE:
      let arr = [...state.data.СообщенияЛК.ИсходящиеСообщения].map((el) =>
        el.UIDСообщения === action.payload.UIDСообщения ? {
          ...el,
          Статус: 'Получено'
        } : el,
      );
      return {
        ...state,
        data: {
          ...state.data,
          СообщенияЛК: {
            ...state.data.СообщенияЛК,
            ИсходящиеСообщения: [...arr]
          },
        },
      };
    case MSG_WORK: {
      return {
        ...state,
        msgWork: action.payload,
      };
    }
    case SET_FILE_IS_LOADING : {
      return {
        ...state,
        fileIsLoading: action.payload
      }
    }
    default:
      return state;
  }
};

// Actions

///// SIMPLE ACTIONS //////

const changeIsLoading = (flag) => {
  return {
    type: CHANGE_IS_LOADING,
    payload: flag,
  };
};

export const clearMessagesServerResponse = () => {
  return {
    type: CLEAR_MESSAGES_SERVER_RESPONSE,
  };
};

export const clearMessages = () => {
  return {
    type: CLEAR_MESSAGES,
  };
};

export const changeMsgWork = (flag) => {
  return {
    type: MSG_WORK,
    payload: flag,
  };
};

export const setFileIsLoading = toggle => {
  return {
    type: SET_FILE_IS_LOADING,
    payload: toggle
  }
}

////// COMPLEX ACTIONS //////

// Получить сообщения по страницам
export const getMessages = (page) => (dispatch) => {
  dispatch(changeIsLoading(true));
  let obj = {
    ...getDefaultObject(),
    НомерПакета: page ? page : 0,
  };
  // console.log('GET MESSAGES');

  return axios
    .post(`${BASE_API_PATH}/messages/get`, obj, getDefaultHeader())
    .then((res) => {
      if (res.data.status && res.data.status === 'error') {
        dispatch(changeGlobalError('1C_error'));
        dispatch(changeIsLoading(false));
      } else {
        if (res.data.return.КодОшибки === '0') {
          dispatch({
            type: ADD_MESSAGES,
            payload: res.data.return,
          });
        } else {
          if (res.data.return.КодОшибки !== '-1' && res.data.return.КодОшибки !== '-2') {
            dispatch({
              type: ADD_MESSAGES_SERVER_RESPONSE,
              payload: res.data.return,
            });
          } else {
            dispatch(changeGlobalError('session_fail'));
          }
        }
      }
    })
    .catch((err) => {
      if (err.response) {
        console.log(err.response);
        dispatch(changeGlobalError('server_error'));
      } else {
        dispatch(changeGlobalError('no_internet'));
      }
    });
};

// Создать сообщение
export const createMessage = (vals) => (dispatch) => {
  let obj = {
    ...getDefaultObject(),
    ...vals,
  };

  let arr = Object.entries(obj)
  // console.log(obj)
  let formData = new FormData();
  arr.map(el => {
    formData.append(el[0], obj[el[0]])
    return el
  });

  // console.log(JSON.stringify(obj));

  return axios
    // .post(`${BASE_API_PATH}/messages/send`, obj, getDefaultHeader())
    .post(`${BASE_API_PATH}/messages/send`, formData, getFileHeader())
    .then((res) => {
      if (
        (res.data.Status && (res.data.Status === 'error' || res.data.Status === 'Error')) ||
        (res.data.status && (res.data.status === 'error' || res.data.status === 'Error'))
      ) {
        dispatch(changeGlobalError('1C_error'));
        dispatch({
          type: ADD_MESSAGES_SERVER_RESPONSE,
          payload: {
            status: 'error',
          },
        });
      } else {
        // console.log(JSON.stringify(res.data))
        if (res.data.return.КодОшибки === '0') {
          dispatch({
            type: ADD_MESSAGES_SERVER_RESPONSE,
            payload: res,
          });
        } else {
          if (res.data.return.КодОшибки !== '-1' && res.data.return.КодОшибки !== '-2') {
            dispatch({
              type: ADD_MESSAGES_SERVER_RESPONSE,
              payload: res.data.return,
            });
          } else {
            dispatch(changeGlobalError('session_fail'));
          }
        }
      }
    })
    .catch((err) => {
      if (err.response) {
        console.log(err.response);
        dispatch(changeGlobalError('server_error'));
        dispatch({
          type: ADD_MESSAGES_SERVER_RESPONSE,
          payload: err.response,
        });
      } else {
        dispatch(changeGlobalError('no_internet'));
      }
    });
};


// Скачать содержимое файла сообщения юзера или сообщения администратора
export const loadFile = (uid, flag) => (dispatch) => {
  let obj = {
    ...getDefaultObject(),
    UIDФайла: uid,
    Входящий: flag,
  };

  dispatch(setFileIsLoading(true));

  return axios
    .post(`${BASE_API_PATH}/messages/get_file_content`, obj, getDefaultHeader())
    .then((res) => {
      dispatch(setFileIsLoading(false));
      if (res.data.status && res.data.status === 'error') {
        dispatch(changeGlobalError('1C_error'));
      } else {
        if (res.data.error_key === '0') {
          if (res.data.content) {
            downloadFileFromUrl(res.data.content, `${res.data.filename}.${res.data.filetype}`);
          };
        } else {
          if (res.data.error_key !== '-1' && res.data.error.key !== '-2') {
            dispatch({
              type: ADD_MESSAGES_SERVER_RESPONSE,
              payload: res.data,
            });
          } else {
            dispatch(changeGlobalError('session_fail'));
          }
        }
      }
    })
    .catch((err) => {
      dispatch({
        type: SET_FILE_IS_LOADING,
        payload: false
      });
      if (err.response) {
        console.log(err.response);
        dispatch(changeGlobalError('server_error'));
      } else {
        dispatch(changeGlobalError('no_internet'));
      }
    });
};

// Подтверждение получения сообщения администратора или подпись сообщения администратора
export const acceptMessage = (vals) => (dispatch, getState) => {

  const state = getState();
  const user_phone = (state.user.data.ИнформацияОКлиенте && state.user.data.ИнформацияОКлиенте.ФизЛицо) ? state.user.data.ИнформацияОКлиенте.ФизЛицо.МобильныйТелефон : null

  let obj = {
    ...getDefaultObject(),
    ...vals,
    ДанныеПодписи: navigator.userAgent,
  };

  if (user_phone) {
    obj.user_phone = user_phone;
  };

  let arr = Object.entries(obj);
  // console.log(obj)

  let formData = new FormData();
  arr.map(el => {
    formData.append(el[0], obj[el[0]])
    return el
  });

  // console.log(JSON.stringify(obj))
  return axios
    // .post(`${BASE_API_PATH}/messages/process`, obj, getDefaultHeader())
    .post(`${BASE_API_PATH}/messages/process`, formData, getFileHeader())
    .then((res) => {
      if (res.data.status && res.data.status === 'error') {
        dispatch(changeGlobalError('1C_error'));
        dispatch(changeMsgWork({
          status: 'error'
        }));
      } else {
        if (res.data.return.КодОшибки === '0') {
          if (vals.Прочитано) {
            dispatch(changeMsgWork({
              ...vals,
              Статус: 'Получено'
            }));
          }

          if (vals.Подписано) {
            dispatch(changeMsgWork({
              ...vals,
              Подписан: true,
              Статус: 'Получено'
            }));
          }
        } else {
          if (res.data.return.КодОшибки !== '-1' && res.data.return.КодОшибки !== '-2') {
            dispatch({
              type: ADD_MESSAGES_SERVER_RESPONSE,
              payload: res.data.return,
            });
          } else {
            dispatch(changeGlobalError('session_fail'));
          }
        }
      }
    })
    .catch((err) => {
      console.log(err);
      if (err.response) {
        console.log(err.response);
        dispatch(changeGlobalError('server_error'));
      } else {
        dispatch(changeGlobalError('no_internet'));
      }
    });
};

export default messages;