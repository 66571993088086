import React, { memo, useEffect, useCallback } from 'react';
import { Modal, Spin } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { clearQrCode, getPaymentQR } from '../../../../../redux/ducks/payment';
import moment from 'moment'

const QRPaymentModal = ({ visible, onCancel, title, initVals }) => {
  const qr = useSelector((state) => state.payment.qr);

  const dispatch = useDispatch();

  const getQRCode = useCallback(() => {
    const vals = {
      ...initVals,
      ДатаОплаты: moment().format('yyyy-MM-DD'),
      ОтчетБланк: false,
      ОтчетКод: true,
    };
    dispatch(getPaymentQR({ vals }));
  }, [dispatch, initVals]);

  useEffect(() => {
    if(visible){
      getQRCode();
    };
  }, [getQRCode, visible]);


  return (
    <Modal
      visible={visible}
      title={title}
      onCancel={() => {
        onCancel()
        dispatch(clearQrCode())
      }}
      centered
      className='info-loan-modal'
    >
      <div
        className='file-wrapper'
        style={{display: 'flex', justifyContent: 'center', minHeight: '200px'}}
      >
        {qr ? (
          <img
            src={qr}
            alt='qr-code'
            width='200px'
          />
        ) : (
          <div className='file-loader'>
            <Spin size='large' />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default memo(QRPaymentModal);
