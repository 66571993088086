import React, { memo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import getPasswordRules from '../../../heplers/getPasswordRules';

const getSymbol = (length) => {
  if(length === 1) {
    return 'символ'
  } else if (length > 1 && length < 5) {
    return 'символа'
  } else {
    return 'символов'
  };
};

const PasswordRules = () => {
  const config = useSelector((state) => state.config);
  const [passwordRules, setPasswordRules] = useState([]);

  useEffect(() => {
    if (
      config['1c_configs'] &&
      config['1c_configs'].return &&
      config['1c_configs'].return.ГлавныеНастройки &&
      config['1c_configs'].return.ГлавныеНастройки.ПараметрыПароля
    ) {
      let params = config['1c_configs'].return.ГлавныеНастройки.ПараметрыПароля.replace(/\s+/g, '').split(',')
      let arr = getPasswordRules(params);
      setPasswordRules(arr);
    };
  }, [config]);

  return (
    <>
      <p>Требования к паролю:</p>
      <ul>
      {passwordRules.map(el => {
          if(el.key === 'Длина'){
            return <li key={el.key}>Минимальная длина пароля <strong>{el.length} {getSymbol(el.length)}</strong>;</li>
          };
          if(el.key === 'Спец'){
            return <li key={el.key}> Должен содержать минимум <strong>{el.length} спец{getSymbol(el.length)}</strong>;</li>
          }
          if(el.key === 'Врег'){
            return <li key={el.key}> Должен содержать минимум <strong>{el.length} {getSymbol(el.length)} в верхнем регистре</strong>;</li>
          }
          if(el.key === 'Цифры'){
            return <li key={el.key}> Должен cодержать минимум <strong>{el.length} цифру;</strong></li>
          }
          return el
        })
      }
      </ul>
    </>
  );
};

export default memo(PasswordRules);
