import React, { memo, useState, useEffect } from 'react';
import { Form, Input } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import getPasswordRules from '../../../heplers/getPasswordRules';
import validatePassword from '../../../heplers/validatePassword';

const Password = ({ isLoading }) => {
  const config = useSelector((state) => state.config);
  const [passwordRules, setPasswordRules] = useState([]);

  useEffect(() => {
    if (
      config['1c_configs'] &&
      config['1c_configs'].return &&
      config['1c_configs'].return.ГлавныеНастройки &&
      config['1c_configs'].return.ГлавныеНастройки.ПараметрыПароля
    ) {
      let params = config['1c_configs'].return.ГлавныеНастройки.ПараметрыПароля.replace(/\s+/g, '').split(',')
      let arr = getPasswordRules(params);
      setPasswordRules(arr);
    };
  }, [config]);

  return (
    <Form.Item
      name='password'
      label='Пароль'
      rules={[
        { required: true, message: 'Введите пароль!' },
        () => ({
          validator(rule, value) {
            // let regExp = /(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/
            // let regExp = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/
            if (!value) {
              return Promise.resolve();
            // } else if (value && !regExp.test(value)) {
            } else if (value && !validatePassword(value, passwordRules)) {
              return Promise.reject('Пароль не соответствует требованиям');
            } else {
              return Promise.resolve();
            };
          },
        }),
      ]}
      hasFeedback
    >
      <Input.Password
        prefix={<LockOutlined className='site-form-item-icon' />}
        disabled={isLoading}
        allowClear
      />
    </Form.Item>
  );
};

export default memo(Password);
