import React, { memo } from 'react';
import { Modal, Spin } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { clearCreditPaymentTable } from '../../../../redux/ducks/user';
import LoanTable from './LoanTable/LoanTable';

const LoanTableModal = ({ visible, onClose, sum }) => {
  const creditTable = useSelector((state) => state.user.credit.creditTable);
  const dispatch = useDispatch();

  return (
    <Modal
      visible={visible}
      title='График платежей'
      onCancel={() => {
        onClose();
        setTimeout(() => {
          dispatch(clearCreditPaymentTable());
        }, 200)
      }}
      className='info-loan-modal'
      centered
    >
      <div className='file-wrapper'
      style={{ minHeight: '30vh', maxHeight: '70vh'}}
      >
        {creditTable ? (
          <LoanTable sum={sum}/>
          // <>Тут будет график</>
        ) : (
          <div className='file-loader'>
            <Spin size='large' />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default memo(LoanTableModal);
